import React from 'react';

const shapes = {
    square: 'rounded-[0px]',
    round: 'rounded-md',
} as const;
const variants = {
    fill: {
        pink_500: 'bg-pink-500 text-white-a700',
        bg_neutral_900: 'bg-neutral-900',
        green_500: 'bg-green-500 text-white-a700',
    },
    gradient: {
        pink_500_pink_A100:
            'bg-gradient text-white-a700 hover:!brightness-[1.15] transition-all',
    },
    outline: {
        pink_500_pink_A100: 'border border-[#f844a4] border-solid',
    },
} as const;
const sizes = {
    xs: 'h-[38px] px-[34px] text-sm',
    sm: 'h-[60px] px-[34px] text-xl',
} as const;

type ButtonProps = Omit<
    React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement> 
        &React.AnchorHTMLAttributes<HTMLAnchorElement>
        &React.LabelHTMLAttributes<HTMLLabelElement>,
        HTMLButtonElement
    >,
    'onClick'
> &
    Partial<{
        className: string;
        leftIcon: React.ReactNode;
        rightIcon: React.ReactNode;
        onClick: () => void;
        shape: keyof typeof shapes;
        variant: keyof typeof variants;
        size: keyof typeof sizes;
        color: string;
        elementType: 'a' | 'button' | 'label';
        href: string;
    }>;
const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
    elementType = 'button' ,
    children,
    className = '',
    leftIcon,
    rightIcon,
    shape,
    variant = 'outline',
    size = 'sm',
    color = 'pink_500',
    ...restProps
}) => {
    const Element = elementType;
    // @ts-ignore
    return (
        // @ts-ignore
        <Element
            className={`${className} flex cursor-pointer flex-row items-center justify-center text-center font-bold ${(shape && shapes[shape]) || ''} ${(size && sizes[size]) || ''} ${(variant && variants[variant]?.[color as keyof (typeof variants)[typeof variant]]) || ''}`}
            {...restProps}
        >
            {!!leftIcon && leftIcon}
            {children}
            {!!rightIcon && rightIcon}
        </Element>
    );
};

export { Button };
